import { Component, OnInit } from '@angular/core';
import { SystemUserService } from '../sharedservices/system-user-services';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing-dashboard',
  templateUrl: './landing-dashboard.component.html',
  styleUrls: ['./landing-dashboard.component.scss']
})
export class LandingDashboardComponent implements OnInit {

  profile: any;
  appitems: any = []
  username: any = []
  // appitems = [
  //   {
  //     label: 'AllEasy Wallet Loading ',
  //     link: 'dashboard/alleasy',
  //     icon: 'account_balance_wallet',
  //     items: [
  //       {
  //         label: 'AllEasy Wallet Loading',
  //         link: 'dashboard/alleasy', 
  //         icon: 'account_balance_wallet',
  //       } 
  //     ]
  //   },
  //   {
  //     label: 'AllValue Gift Card',
  //     link: 'dashboard/allvalue/avgmain',
  //     icon: 'card_giftcard',
  //     items: [
  //       {
  //         label: 'Redemption',
  //         link: 'dashboard/allvalue/sale',
  //         icon: 'shopping_cart',
  //       },
  //       {
  //         label: 'Activate Card',
  //         link: 'dashboard/allvalue/activate',
  //         icon: 'credit_card',
  //       },
  //       {
  //         label: 'Check Balance',
  //         link: 'dashboard/allvalue/balance',
  //         icon: 'account_balance_wallet'
  //       },
  //       {
  //         label: 'Card Transaction History',
  //         link: 'dashboard/allvalue/cardtxn',
  //         icon: 'list', 
  //       },
  //       // {
  //       //   label: 'Request for Refund',
  //       //   link: 'dashboard/allvalue/refund',
  //       //   icon: 'list', 
  //       // },
       
  //     ]
  //   }, 
  //   {
  //     label: 'My Transactions',
  //     link: 'dashboard/allvalue/reports',
  //     icon: 'list_alt',
  //     items: [
  //       {
  //         label: 'Wallet Loading',
  //         link: 'dashboard/allvalue/reports/walletloading',
  //         icon: '',
  //       },
  //       {
  //         label: 'Gift Card Transactions',
  //         link: 'dashboard/allvalue/reports/giftcardtxn',
  //         icon: '',
  //       } ] 
  //   },
    
  

  // ];


  admin_appitems = [ {
    label: 'Transactions',
    link: 'dashboard/allvalue/reports',
    icon: 'list_alt',
    items: [
      {
        label: 'Wallet Loading',
        link: 'dashboard/allvalue/reports/walletloading',
        icon: '',
      },
      {
        label: 'Gift Card Transactions',
        link: 'dashboard/allvalue/reports/giftcardtxn', 
        icon: 'redeem',
      } ] 
  },
  {
    label: 'Gift Cards',
    link: 'dashboard/allvalue/reports',
    icon: 'list_alt',
    items: [
      {
        label: 'Gift Card List',
        link: 'dashboard/allvalue/reports/walletloading',
        icon: '',
      },
      {
        label: 'Gift Card Accounts',
        link: 'dashboard/allvalue/reports/giftcardtxn',
        icon: '',
      } ] 
  },
  {
    label: 'GC Users',
    link: 'dashboard/admins',
    icon: 'list_alt',
    items: [
      {
        label: 'Users List',
        link: 'dashboard/admins/users/merchant-users',
        icon: '',
      } 
     ] 
  },
  {
    label: 'Merchants',
    link: 'dashboard/admins',
    icon: 'list_alt',
    items: [
      {
        label: 'Company',
        link: 'dashboard/admins/merchant/company',
        icon: '',
      },
      {
        label: 'Brands',
        link: 'dashboard/admins/merchant/brands',
        icon: '',
      },
      {
        label: 'Branch',
        link: 'dashboard/admins/merchant/branch',
        icon: '',
      }
     ] 
  },

]
  
  config = {
    paddingAtStart: true,
    interfaceWithRoute: true,
    classname: 'my-custom-class',
    listBackgroundColor: `rgb(255,255,255)`,
    fontColor: `rgb(0, 0, 0)`,
    backgroundColor: `rgb(255,255,255)`,
    selectedListFontColor: `rgb(243 , 243 , 243)`,
    highlightOnSelect: true,
    collapseOnSelect: true,
    rtlLayout: false
};
  
  
  // businessunits = [
  //   {
  //     id: 1,
  //     title: 'ALLPAY WALLET',
  //     img: '',
  //     link: 'allpay/main'
      
  //   },
  //   // {
  //   //   id: 2,
  //   //   title: 'GETS',
  //   //   img: 'assets/gets.png',
  //   //   link: '/gets'
  //   // },
  //   {
  //     id: 3,
  //     title: 'ALLVALUE',
  //     img: 'assets/allvalue.jpg',
  //     link: '/allvalue/avgmain'
  //   },
  //   // {
  //   //   id: 4,
  //   //   title: 'VISTA CINEMA',
  //   //   img: 'assets/vistacinema.png',
  //   //   link: '/vistacinema'
  //   // } 
  // ];

  storecode: string = "";
  isMenuOpen: boolean = true;
  contentMargin: number = 240;
  constructor(private route : Router,
    private spinner: NgxSpinnerService,
    private systemusersvc : SystemUserService,
    ) { }

  ngOnInit() {
    this.getClaims();
    this.username = localStorage.getItem("username");
    this.storecode = localStorage.getItem("storecode");
    if(this.storecode == "KC" ){
      this.appitems = [
        {
          label: 'AllEasy Wallet Loading ',
          link: 'dashboard/alleasy',
          icon: 'wallet',
          items: [
            {
              label: 'AllEasy Wallet Loading',
              link: 'dashboard/alleasy', 
              icon: 'account_balance_wallet',
            }
          ]
          
        },
        {
          label: 'My Transactions',
          link: 'dashboard/allvalue/reports',
          icon: 'list_alt',
          items: [
            {
              label: 'Wallet Loading',
              link: 'dashboard/allvalue/reports/walletloading',
              icon: '',
            } 
          ] 
        },
        
        
    
      ];
      return;
    }

    if(this.storecode == "BT"  || this.storecode == "BB"  || this.storecode == "CD"  || this.storecode == "CP"  || this.storecode == "DJ" || this.storecode == "CN"  || this.storecode == "AT"  
    || this.storecode == "CY"  ||this.storecode == "FF"  || this.storecode == "ASP" || this.storecode == "KAL" || this.storecode == "NN"
    || this.storecode == "YB"  ||this.storecode == "PC" || this.storecode == "MKTLIB" || this.storecode == "ALLBUILD" || this.storecode == "BMD"  ){
      this.appitems = [
        {
          label: 'AllValue Gift Card',
          link: 'dashboard/allvalue/avgmain',
          icon: 'card_giftcard',
          items: [
            {
              label: 'Redemption',
              link: 'dashboard/allvalue/sale',
              icon: 'shopping_cart',
            },
            {
              label: 'Activate Card',
              link: 'dashboard/allvalue/activate',
              icon: 'credit_card',
            },
            {
              label: 'Check Balance',
              link: 'dashboard/allvalue/balance',
              icon: 'account_balance_wallet'
            },
            {
              label: 'Card Transaction History',
              link: 'dashboard/allvalue/cardtxn',
              icon: 'list', 
            },
            // {
            //   label: 'Request for Refund',
            //   link: 'dashboard/allvalue/refund',
            //   icon: 'list', 
            // },
           
          ]
        },
        {
          label: 'My Transactions',
          link: 'dashboard/allvalue/reports',
          icon: 'list_alt',
          items: [
            // {
            //   label: 'Wallet Loading',
            //   link: 'dashboard/allvalue/reports/walletloading',
            //   icon: '',
            // },
            {
              label: 'Gift Card Transactions',
              link: 'dashboard/allvalue/reports/giftcardtxn',
              icon: 'redeem',
            } 
          ] 
        },
        
        
    
      ];
      return;
    }

    if(this.storecode == "ADS" || this.storecode == "AH" || this.storecode == 'ALLP'){
      this.appitems = [
        {
          label: 'AllEasy Wallet Loading ',
          link: 'dashboard/alleasy',
          icon: 'wallet',
          items: [
            {
              label: 'AllEasy Wallet Loading',
              link: 'dashboard/alleasy', 
              icon: 'account_balance_wallet',
            }
          ]
          
        },
        {
          label: 'AllValue Gift Card',
          link: 'dashboard/allvalue/avgmain',
          icon: 'card_giftcard',
          items: [
            {
              label: 'Redemption',
              link: 'dashboard/allvalue/sale',
              icon: 'shopping_cart',
            },
            {
              label: 'Activate Card',
              link: 'dashboard/allvalue/activate',
              icon: 'credit_card',
            },
            {
              label: 'Check Balance',
              link: 'dashboard/allvalue/balance',
              icon: 'account_balance_wallet'
            },
            {
              label: 'Card Transaction History',
              link: 'dashboard/allvalue/cardtxn',
              icon: 'list', 
            },
            // {
            //   label: 'Request for Refund',
            //   link: 'dashboard/allvalue/refund',
            //   icon: 'list', 
            // },
           
          ]
        },
        {
          label: 'My Transactions',
          link: 'dashboard/allvalue/reports',
          icon: 'list_alt',
          items: [
            {
              label: 'Wallet Loading',
              link: 'dashboard/allvalue/reports/walletloading',
              icon: 'format_list_numbered',
            },
            {
              label: 'Gift Card Transactions',
              link: 'dashboard/allvalue/reports/giftcardtxn',
              icon: 'redeem',
            } 
          ] 
        },
        
        
    
      ];
      return;
    }

    if(this.storecode == "PRMAIN" || this.storecode == "FPEAK" || this.storecode == "AC"){
      this.appitems = [
        {
          label: 'AllEasy Wallet Loading ',
          link: 'dashboard/alleasy',
          icon: 'wallet',
          items: [
            {
              label: 'AllEasy Wallet Loading',
              link: 'dashboard/alleasy', 
              icon: 'account_balance_wallet',
            }
          ]
          
        },
        {
          label: 'My Transactions',
          link: 'dashboard/allvalue/reports',
          icon: 'list_alt',
          items: [
            {
              label: 'Wallet Loading',
              link: 'dashboard/allvalue/reports/walletloading',
              icon: '',
            } 
          ] 
        },
        
        
    
      ];
      return;
    }

 
    if(localStorage.getItem("utype") == "4"){
      this.appitems = [
        {
          label: 'AllEasy Wallet Loading ',
          link: 'dashboard/alleasy',
          icon: 'wallet',
          items: [
            {
              label: 'AllEasy Wallet Loading',
              link: 'dashboard/alleasy', 
              icon: 'account_balance_wallet',
            },
           
          ]
          
        },
        {
          label: 'AllValue Gift Card',
          link: 'dashboard/allvalue/avgmain',
          icon: 'card_giftcard',
          items: [
            {
              label: 'Redemption',
              link: 'dashboard/allvalue/sale',
              icon: 'shopping_cart',
            },
            {
              label: 'Check Balance',
              link: 'dashboard/allvalue/balance',
              icon: 'account_balance_wallet'
            },
            {
              label: 'Card Transaction History',
              link: 'dashboard/allvalue/cardtxn',
              icon: 'list', 
            }
          ]
        }, 
        {
          label: 'My Transactions',
          link: 'dashboard/allvalue/reports',
          icon: 'list_alt',
          items: [
            {
              label: 'Wallet Loading',
              link: 'dashboard/allvalue/reports/walletloading',
              icon: '',
            },
            {
              label: 'Gift Card Transactions',
              link: 'dashboard/allvalue/reports/giftcardtxn',
              icon: 'redeem',
            },
           
          ] 
        },
        
        
    
      ];
       
    }else if(localStorage.getItem("utype") == "19"){
      this.appitems = [
        {
          label: 'AllEasy Wallet Loading ',
          link: 'dashboard/alleasy',
          icon: 'account_balance_wallet',
          items: [
            {
              label: 'AllEasy Wallet Loading',
              link: 'dashboard/alleasy', 
              icon: 'account_balance_wallet',
            },
            {
              label: 'GEts',
              link: 'dashboard/alleasy', 
              icon: 'account_balance_wallet',
            }, 
            {
              label: 'Vista Cinema Gift Card',
              link: 'dashboard/cinema',
              icon: 'local_movies',
            },
          ]
        },
        {
          label: 'AllValue Gift Card',
          link: 'dashboard/allvalue/avgmain',
          icon: 'card_giftcard',
          items: [
            {
              label: 'Activate Card',
              link: 'dashboard/allvalue/activate',
              icon: 'credit_card',
            },
            {
              label: 'Check Balance',
              link: 'dashboard/allvalue/balance',
              icon: 'account_balance_wallet'
            },
            {
              label: 'Card Transaction History',
              link: 'dashboard/allvalue/cardtxn',
              icon: 'list', 
            },
            // {
            //   label: 'Request for Refund',
            //   link: 'dashboard/allvalue/refund',
            //   icon: 'list', 
            // },
           
          ]
        },
        {
          label: 'My Transactions',
          link: 'dashboard/allvalue/reports',
          icon: 'list_alt',
          items: [
            {
              label: 'Wallet Loading',
              link: 'dashboard/allvalue/reports/walletloading',
              icon: '',
            },
            {
              label: 'Gift Card Transactions',
              link: 'dashboard/allvalue/reports/giftcardtxn',
              icon: 'redeem',
            } 
          ] 
        },
        
        // {
        //   label: 'Sign Out',
        //   link: 'index',
        //   icon: 'exit_to_app', 
        // }
    
    
      ];
     
    }else if(localStorage.getItem("utype") == "18"){
      this.appitems = [
        {
          label: 'AllEasy Wallet Loading ',
          link: 'dashboard/alleasy',
          icon: 'account_balance_wallet',
          items: [
            {
              label: 'AllEasy Wallet Loading',
              link: 'dashboard/alleasy', 
              icon: 'account_balance_wallet',
            },
            {
              label: 'GEts',
              link: 'dashboard/alleasy', 
              icon: 'account_balance_wallet',
            }, 
            {
              label: 'Vista Cinema Gift Card',
              link: 'dashboard/cinema',
              icon: 'local_movies',
            },
          ]
        },
        {
          label: 'AllValue Gift Card',
          link: 'dashboard/allvalue/avgmain',
          icon: 'card_giftcard',
          items: [
            {
              label: 'Redemption',
              link: 'dashboard/allvalue/sale',
              icon: 'shopping_cart',
            },
            {
              label: 'Activate Card',
              link: 'dashboard/allvalue/activate',
              icon: 'credit_card',
            },
            {
              label: 'Check Balance',
              link: 'dashboard/allvalue/balance',
              icon: 'account_balance_wallet'
            },
            {
              label: 'Card Transaction History',
              link: 'dashboard/allvalue/cardtxn',
              icon: 'list', 
            },
  
           
          ]
        },
        {
          label: 'My Transactions',
          link: 'dashboard/allvalue/reports',
          icon: 'list_alt',
          items: [
            {
              label: 'Wallet Loading',
              link: 'dashboard/allvalue/reports/walletloading',
              icon: '',
            },
            {
              label: 'Gift Card Transactions',
              link: 'dashboard/allvalue/reports/giftcardtxn',
              icon: 'redeem',
            } 
          ] 
        },
        
    
    
    
      ];
     
    }


   


  }

  getPage(outlet) {
    return outlet.activatedRouteData['page'] || 'one';
  }

  getClaims(): void {
    this.systemusersvc.getUserClaims().subscribe({
      next: this.handleGetClaimsResponse.bind(this),
      error: this.handleGetClaimsError.bind(this)
    })
  }


  handleGetClaimsResponse(res: any){
    this.profile = res;  
  }

  handleGetClaimsError(err: any){ 
  }

  toggleMenu(){
    console.log("on toolbar toggle ", this.isMenuOpen);
    
    this.isMenuOpen = !this.isMenuOpen

    if(!this.isMenuOpen){
      this.contentMargin = 70;
    }else{
      this.contentMargin = 240;
    }
  }

}
